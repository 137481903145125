import React from 'react';
import { Jumbotron, Button, Image, Col, Row, Container} from 'react-bootstrap';
import image from './media/29.jpg';


const Firstpage=()=>{

    return(
        <div className="divstyle">
            <Container className="containerstyle">
                <Row>
                    <Col className= "colstyle">
                    <Jumbotron className="headingstyle">
                        <h1>Smart solutions for Buisnesses</h1>
                            <p>
                            Build and Launch your buisness faster with E-Pilier Software, Legal and Marketing Solutions
                            </p>
                            <p>
                                    <Button variant="primary">Learn more</Button>
                            </p>
                    </Jumbotron>
                        {/* <div className="headtextstyle">
                        <h1 className="headertext">Smart solutions for Buisnesses </h1>
                        <h5 className="paratext">Build and Launch your buisness faster with E-Pilier Software, Legal and Marketing Solutions</h5>
                        </div>
                        <Button className= "buttonstyle" href="#">Let's Connect</Button>{' '} */}
                        
                    </Col>
                    <Col> 
                    <Image src={image} fluid />
                    </Col>
                </Row>
            </Container>
      </div>
    )
}

export default Firstpage
