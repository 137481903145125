import React from 'react';
import { Row, Col} from 'react-bootstrap';
import logo from "./media/logo.png";

const Footer=()=>{
    return(
        <div className="footercontainer">
            <Row className="rowcontainer">
                <Col className= "footertextstyle">
                <img className="logostyle" src={logo} alt="e-pilier logo"></img>
                <p>©2021 E-Pilier, All Rights Reserved</p>
                </Col>
                <Col className="footertextstyle">
    
                    <div className="liststyle">
                        <ul><h5>Company</h5></ul>
                        <ul>About Us</ul>
                        <ul>Contact Us</ul>
                    </div>
    
                </Col>
                <Col className= "footertextstyle">
                <div className="liststyle">

                        <ul><h5>Terms</h5></ul>
                            <ul>Terms and Conditions</ul>
                            <ul>Privacy Policy</ul>
                </div>

                </Col>
                <Col className= "footertextstyle">
                <div className="liststyle">
                    <ul>
                        <h5>Products</h5></ul>
                        
                            <ul>CurateMails</ul>
                            <ul>MezChip</ul>
                            
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Footer