import React from 'react';
import { Carousel} from 'react-bootstrap';

const Reviews=()=>{
    const testimonial = [
        {
          content:
            "E-Pilier services doesn’t come without a price tag. But it's worth it.",
          author: "Avnish-Product Architect, KNEX Inc."
        },
        {
          content:
            "E-Pilier did great work on our web application, making it mobile compatible. I really appreciated how he committed himself to understanding our needs.",
          author: "Vishal Kumar- Co-Founder, OneUP"
        },
        {
          content:
            "Working with Anurag has been really good. His ability to convert Sketch designs to code is really applaudable.",
          author: "Harshwardhan Raikwar., CEO,Guarented"
        },
      ];
    
      return (
        <div className="testimonialwrapper">
            {/* <div className="textwrapper">
                <h1>Testimonials</h1>
                <p>What our Clients Say</p>
            </div> */}
        <div>
          <Carousel interval={1000}>
            {testimonial.map((c, index) => {
              return (
                <Carousel.Item interval={5000}>
                  <div style={{ height: 200, background: "#3fb0ac", color: "black", textAlign:"center" }}>
                    <p className="testimonialstyle">{c.content}</p>
                  </div>
                  <Carousel.Caption>
                    <p className="captionstyle">{c.author}</p>
                  </Carousel.Caption>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
        </div>
      );
    }

export default Reviews