import React from 'react';
import { Card, Col, Row, Container} from 'react-bootstrap';
import recruiterflow from './media/recruiterflow.jpg';
import sendfast from './media/sendfast.jpg';
import gua from './media/gua.jpg';
import oneup from './media/oneup.png';
import curate from './media/curate.png';
import chailatte from './media/chailatte.jpg';

const Products=()=>{
    return(
        <div className= "producstwrapper">
            <div className= "producttextstyle">
            <h1>Portfolio</h1>
            <p>Take a glimpse of our past work</p>
            </div>

<Container>
      <div className= "portfoliorowstyle">
          {/* first row */}
    <Row>
      <Col>
        <Card className="producthoverstyle" style={{ width: '30rem' }}>
        <Card.Img className="productimagestyle" variant="top" src= {gua}/>
        </Card>
      </Col>

      <Col><Card className="producthoverstyle" style={{ width: '30rem' }}>
        <Card.Img className="productimagestyle" variant="top" src={sendfast} />
        </Card></Col>
    </Row>
    </div>
        {/* end of first row */}
        {/* second row */}
    <div className= "portfoliorowstyle">
    <Row>
      <Col>
        <Card className="producthoverstyle" style={{ width: '30rem' }}>
        <Card.Img className="productimagestyle" variant="top" src={oneup} />
        </Card>
      </Col>

      <Col>
        <Card className="producthoverstyle" style={{ width: '30rem' }}>
        <Card.Img className="productimagestyle" variant="top" src={recruiterflow} />
        </Card>
      </Col>
    </Row>
    {/* end of second row */}
    </div>

    <div className= "portfoliorowstyle">
        {/* third row */}
    <Row>

      <Col><Card className="producthoverstyle" style={{ width: '30rem' }}>
        <Card.Img className="productimagestyle" variant="top" src={curate} />
        </Card></Col>
      <Col>
        <Card className="producthoverstyle" style={{ width: '30rem' }}>
        <Card.Img className="productimagestyle" variant="top" src={chailatte} />
        </Card>
      </Col>
  </Row>
  {/* end of third row */}
  </div>
</Container>
</div>

    )
}

export default Products