import React from 'react';
import { Card, Col, Row, Carousel} from 'react-bootstrap';
import image1 from './media/image1.jpg';
import image2 from './media/image2.jpg';
import image3 from './media/image3.jpg';


const Blog=()=>{


    return(
        <div className= "blogcontainer">
            <div className="blogheader">
            <h1>Blog</h1>
            <p>Get the latest insights from our Experts</p>
            </div>
        <div>
        <Carousel fade>
                    <Carousel.Item className="carouselstyle">
                        <Row>
                            <Col>
                        <Card className="blogstyle" style={{ width: '20rem' }}>
                        <Card.Img className= "picstyle" variant="top" src={image1} />
                        <Card.Body className= "bodystyle">
                            <Card.Title>Are you Protecting your Intellectual Property Rights</Card.Title>
                            <Card.Text>A brandname for your business is unique, well thought upon and worked hard on. Having someone steal it or embroiling yourself in a litigation later over someone else's trademark, is not something you would want right?</Card.Text>
                            <Card.Link href="#">Read More</Card.Link>
                        </Card.Body>
                        </Card>
                        </Col>
                        <Col>
                        <Card className="blogstyle" style={{ width: '20rem' }}>
                        <Card.Img className= "picstyle" variant="top" src={image2} />
                        <Card.Body className= "bodystyle">
                            <Card.Title>Are you a home chef selling food to COVID hit families? Know the law.</Card.Title>
                            <Card.Text>With every passing day India is seing itself grappling under the tightening knots of this mutant virus. Covid hit families are finding it difficult to cope with the disease, the stress and the isolation.</Card.Text>
                            <Card.Link href="#">Read More</Card.Link>
                        </Card.Body>
                        </Card>
                        </Col>
                        <Col>
                        <Card className="blogstyle" style={{ width: '20rem' }}>
                        <Card.Img className= "picstyle" variant="top" src={image3} />
                        <Card.Body className= "bodystyle">
                            <Card.Title>How to Set Up A Food Business From Home?</Card.Title>
                            <Card.Text>In exploring a new side of our personalities, I saw a lot of people turning into home bakers, chef and solopreneurs. They often would ask me how to continue their passion of cooking/ baking while making an earning out of it.</Card.Text>
                            <Card.Link href="#">Read More</Card.Link>
                        </Card.Body>
                        </Card>
                        </Col>
                        </Row>
                    </Carousel.Item>
                {/* )
            })} */}
        </Carousel>
        
        </div>
        </div>
    )
}

export default Blog