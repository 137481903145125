import React from 'react';
import { Card, Col, Row, Container,} from 'react-bootstrap';
import billboard from './media/billboard.png';
import check from './media/check.png';
import customize from './media/customize.png';
import development from './media/development.png';
import networking from './media/networking.png';
import seotag from './media/seo-tag.png';



const Services=()=>{
    return(

<div className="servicewrapper">
      <div className="textstyle"> 
        <h1>Services</h1>
        <p>What we offer</p>
      </div>
      <br/>
<div>
    <Container>
      <div className="servicerowstyle"> 
    <Row>
      <Col>
        <Card className="servicecardstyle" style={{ width: '18rem' }}>
        <Card.Img className="imagestyle" variant="top" src= {billboard}/>
        <Card.Body>
        <Card.Title>Designing Frontend Architecture</Card.Title>
        <Card.Text>
        Some quick example text to build on the card title and make up the bulk of
        the card's content.
        </Card.Text>
        {/* <Button variant="primary">Go somewhere</Button> */}
        </Card.Body>
        </Card>
      </Col>
      <Col><Card className="servicecardstyle" style={{ width: '18rem' }}>
        <Card.Img className="imagestyle" variant="top" src={development} />
        <Card.Body>
        <Card.Title>Design to Development</Card.Title>
        <Card.Text>
        Some quick example text to build on the card title and make up the bulk of
        the card's content.
        </Card.Text>
        {/* <Button variant="primary">Go somewhere</Button> */}
        </Card.Body>
        </Card></Col>
      <Col>
        <Card className="servicecardstyle" style={{ width: '18rem' }}>
        <Card.Img className="imagestyle" variant="top" src={customize} />
        <Card.Body>
        <Card.Title>Custom Software Requirements</Card.Title>
        <Card.Text>
        Some quick example text to build on the card title and make up the bulk of
        the card's content.
        </Card.Text>
        {/* <Button variant="primary">Go somewhere</Button> */}
        </Card.Body>
        </Card>
      </Col>
  </Row>
  </div>
  <div className="servicerowstyle">
  <Row>
      <Col>
        <Card className="servicecardstyle" style={{ width: '18rem' }}>
        <Card.Img className="imagestyle" variant="top" src={check} />
        <Card.Body>
        <Card.Title>Drafting Terms of Service and Website Policies</Card.Title>
        <Card.Text>
        Some quick example text to build on the card title and make up the bulk of
        the card's content.
        </Card.Text>
        {/* <Button variant="primary">Go somewhere</Button> */}
        </Card.Body>
        </Card>
      </Col>
      <Col><Card className="servicecardstyle" style={{ width: '18rem' }}>
        <Card.Img className="imagestyle" variant="top" src={networking} />
        <Card.Body>
        <Card.Title>Social Media Management</Card.Title>
        <Card.Text>
        Some quick example text to build on the card title and make up the bulk of
        the card's content.
        </Card.Text>
        {/* <Button variant="primary">Go somewhere</Button> */}
        </Card.Body>
        </Card></Col>
      <Col>
        <Card className="servicecardstyle" style={{ width: '18rem' }}>
        <Card.Img className="imagestyle" variant="top" src={seotag} />
        <Card.Body>
        <Card.Title>Website SEO</Card.Title>
        <Card.Text>
        Some quick example text to build on the card title and make up the bulk of
        the card's content.
        </Card.Text>
        {/* <Button variant="primary">Go somewhere</Button> */}
        </Card.Body>
        </Card>
      </Col>
  </Row>
  </div>
</Container>
</div>
</div>
    )
}

export default Services