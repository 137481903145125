import React from "react";
import Header from "./header";
import Firstpage from "./firstpage";
import Services from "./services";
import Reviews from "./reviews";
import Products from "./products";
import Blog from "./blog";
import Footer from "./footer";
import './App.scss';


const App=()=>{

return (<div>
<Header/>
<Firstpage/>
<Services/>
<Reviews/>
<Products/>
<Blog/>
<Footer/>
</div>)
}

export default App